<template>
  <!-- BEGIN: Content-->
  <div
    v-if="user && convo && round"
    class="app-content content "
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ user.full_name }}
                <strong
                  v-if="round == 6"
                  class="custom-badge bg-light-success ms-2"
                ><span class="text-dark">Mark:</span> {{ user.eval_data.avg_grade }}</strong>
                <br>
                <small>{{ convo.title }} · Applicant</small>
                <br>
                <span
                  v-if="round == 5 && user.eval_data.competent"
                  class="text-success"
                >
                  <i data-feather="circle" /> <small>Round 0 - Evaluated</small>
                </span>
                <span
                  v-if="round == 5 && !user.eval_data.competent"
                  class="text-danger"
                >
                  <i data-feather="circle" /> <small>Round 0 - Pending evaluation</small>
                </span>
                <span
                  v-if="round == 6 && user.eval_data.grading_date"
                  class="text-success"
                >
                  <i data-feather="circle" /> <small>Round 1 - Evaluated</small>
                </span>
                <span
                  v-if="round == 6 && !user.eval_data.avg_grade"
                  class="text-danger"
                >
                  <i data-feather="circle" /> <small>Round 1 - Pending evaluation</small>
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to applicants list"
              @click="$router.push({ name: 'evaluator.calls.evaluations' })"
            ><i data-feather="chevron-left" /></a>
            <a
              class="btn btn-primary ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Download zip"
              @click="print"
            ><i data-feather="file" /> Download zip</a>
          </div>
        </div>
      </div>

      <!-- IMPORTANTE: las acciones que se realizan durante una ronda puede consultarse en rondas posteriores pero NO modificarlas -->
      <div
        class="card"
      >
        <div
          v-b-toggle="`collapseFormMessage`"
          class="card-header"
          @click="resizeArea"
        >
          <h3 class="cart-title">
            <a data-action="collapse">Feedback message </a>
          </h3>
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <a data-action="collapse"><i data-feather="chevron-down" /></a>
              </li>
            </ul>
          </div>
        </div>
        <b-collapse
          :id="`collapseFormMessage`"
        >
          <div class="card-content">
            <div class="card-body">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Feedback message</label>
                <textarea
                  ref="feedback_msg"
                  v-model="feedback_message"
                  class="form-control"
                />
              </div>
              <div
                class="mb-1"
              >
                <button
                  class="btn btn-outline-success"
                  @click="rateApplicant({
                    feedback_message: feedback_message,
                  })"
                >
                  <i data-feather="save" /> Save feedback message
                </button>
                <button
                  class="btn btn-success ms-1"
                  @click="rateApplicant({
                    feedback_message: feedback_message,
                    feedback_date: true
                  })"
                >
                  <i data-feather="send" /> Submit feedback message
                </button>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <hr>

      <!-- aparece en ronda 1 -->
      <div
        class="card mb-5"
      >
        <div
          v-b-toggle="`collapseFormReport`"
          class="card-header"
          @click="resizeArea"
        >
          <h3 class="cart-title">
            <a data-action="collapse">Your evaluation </a>
          </h3>
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <a data-action="collapse"><i data-feather="chevron-down" /></a>
              </li>
            </ul>
          </div>
        </div>
        <b-collapse
          :id="`collapseFormReport`"
        >
          <div class="card-content">
            <div class="card-body">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Strengths</label>
                <textarea
                  id="strength"
                  ref="strength"
                  v-model="user.eval_data.stregths"
                  class="form-control"
                  :min-height="30"
                  disabled
                />
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Weaknesses</label>
                <textarea
                  id="weak"
                  ref="weak"
                  v-model="user.eval_data.weaknesses"
                  class="form-control"
                  :min-height="30"
                  disabled
                />
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >General comments</label>
                <textarea
                  id="general"
                  ref="general"
                  v-model="user.eval_data.general_comments"
                  class="form-control"
                  :min-height="30"
                  disabled
                />
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Mark</label>
                <!-- <p class="form-text mb-1">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </p> -->
                <input
                  v-model="user.eval_data.avg_grade"
                  type="number"
                  min="0"
                  max="10"
                  step="0.1"
                  class="ms-1"
                  disabled
                > <!-- limitar este campo: valor entre 0 y 10, máximo un decimal -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        v-for="evaluator in evaluations"
        :key="evaluator.id"
        class="card"
      >
        <div
          v-b-toggle="`collapseFormReport-${evaluator.id}`"
          class="card-header"
          @click="resizeArea"
        >
          <h3 class="cart-title">
            <a data-action="collapse">{{ evaluator.full_name }} </a>
          </h3>
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <a data-action="collapse"><i data-feather="chevron-down" /></a>
              </li>
            </ul>
          </div>
        </div>
        <b-collapse
          :id="`collapseFormReport-${evaluator.id}`"
        >
          <div class="card-content">
            <div class="card-body">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Strengths</label>
                <textarea
                  id="strength"
                  :ref="'strength-' + evaluator.id"
                  v-model="evaluator.pivot.stregths"
                  class="form-control"
                  :min-height="30"
                  disabled
                />
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Weaknesses</label>
                <textarea
                  id="weak"
                  :ref="'weak-' + evaluator.id"
                  v-model="evaluator.pivot.weaknesses"
                  class="form-control"
                  :min-height="30"
                  disabled
                />
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >General comments</label>
                <textarea
                  id="general"
                  :ref="'general-' + evaluator.id"
                  v-model="evaluator.pivot.general_comments"
                  class="form-control"
                  :min-height="30"
                  disabled
                />
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Mark</label>
                <!-- <p class="form-text mb-1">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </p> -->
                <!-- <p class="form-text mb-1">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </p> -->
                <input
                  v-model="evaluator.pivot.avg_grade"
                  type="number"
                  min="0"
                  max="10"
                  step="0.1"
                  class="ms-1"
                  disabled
                > <!-- limitar este campo: valor entre 0 y 10, máximo un decimal -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <hr>
      <!-- aparece en ronda 1 -->

      <div
        class="row pb-2"
      >
        <div class="col">
          <div class="viewform__actions--buttons pb-2">
            <a
              class="btn btn-sm btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Display all blocks"
              @click="toggleAllSections(false)"
            ><i data-feather="maximize-2" /> Display all</a>
            <a
              class="btn btn-sm btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse all blocks"
              @click="toggleAllSections(true)"
            ><i data-feather="minimize-2" /> Collapse all</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <PreviewSection
            v-for="(section, index) in sections"
            :key="section.id"
            :section="section"
            :user-id="user.user_id"
            :index="(index + 1)"
          />
        </div>
      </div>
    </div>
    <!-- END: Content-->
  </div></template>

<script>
import PreviewSection from '@/views/convos/partials/preview/PreviewSection.vue'
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    PreviewSection,
    BCollapse,
  },
  data() {
    return {
      user: {},
      round: 0,
      convo: {},
      feedback_message: '',
    }
  },
  computed: {
    ...mapGetters({
      sections: 'convo/sections',
      evaluations: 'convo/evaluations',
    }),
    current_access() {
      return this.convo.current_access ? this.convo.current_access.type : null
    },
  },
  async mounted() {
    if (!this.$route.params.user || !this.$route.params.round || !this.$route.params.convo) {
      this.$router.push({ name: 'evaluator.calls.evaluations' })
    } else {
      this.user = this.$route.params.user
      this.round = this.$route.params.round
      this.convo = this.$route.params.convo
      this.feedback_message = this.user.eval_data.feedback_message ? this.user.eval_data.feedback_message : this.user.eval_data.general_comments

      this.$store.dispatch('convo/fetchUserSections', [this.$route.params.convo.id, this.user.user_id])
      await this.$store.dispatch('convo/fetchEvaluations', { convoId: this.$route.params.convo.id, userId: this.user.user_id })

      setTimeout(() => {
        this.resizeArea()
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 2000)
    }
  },
  methods: {
    resizeArea() {
      setTimeout(() => {
        this.$refs.general.style.height = `${this.$refs.general.scrollHeight}px`
        this.$refs.strength.style.height = `${this.$refs.strength.scrollHeight}px`
        this.$refs.weak.style.height = `${this.$refs.weak.scrollHeight}px`
        this.$refs.feedback_msg.style.height = `${this.$refs.feedback_msg.scrollHeight}px`

        this.evaluations.forEach(e => {
          this.$refs[`general-${e.id}`][0].style.height = `${this.$refs[`general-${e.id}`][0].scrollHeight}px`
          this.$refs[`strength-${e.id}`][0].style.height = `${this.$refs[`strength-${e.id}`][0].scrollHeight}px`
          this.$refs[`weak-${e.id}`][0].style.height = `${this.$refs[`weak-${e.id}`][0].scrollHeight}px`
        })
      }, 500)
    },
    toggleAllSections(closed) {
      this.$store.dispatch('preview/toggleSections', closed)
      if (!closed) {
        this.$store.dispatch('preview/loadAllGroups')
      }
    },
    print() {
      this.toggleAllSections(false)
      document.body.classList.add('print')
      setTimeout(() => {
        window.print()
        document.body.classList.remove('print')
      }, 700)
    },
    rateApplicant(data) {
      if (this.current_access == this.round || this.round == 8) {
        Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/evaluate-applicant`, {
          userId: this.user.user_id,
          confirm: this.current_access > 6,
          ...data,
        }).then(() => {
          this.$toastr.success('', 'Applicant status updated successfully!')
          if (data.competent) {
            this.user.eval_data.competent = data.competent
          }

          if (data.grading_date || data.feedback_date) {
            this.$router.push({ name: 'evaluator.calls.evaluations' })
          }
        })
      } else {
        this.$toastr.error('', 'Deadline has ended to changes this round!', {
          duration: 0,
        })
      }
    },
  },
}
</script>
